import { JSX, For, createSignal, ParentProps } from "solid-js";

import Antenna from "#lucide-solid/icons/antenna";
import Mic from "#lucide-solid/icons/mic";
import Gamepad2 from "#lucide-solid/icons/gamepad-2";
import MessageCircleMore from "#lucide-solid/icons/message-circle-more";
import GraduationCap from "#lucide-solid/icons/graduation-cap";
import Megaphone from "#lucide-solid/icons/megaphone";
import Globe from "#lucide-solid/icons/globe";
import Cpu from "#lucide-solid/icons/cpu";

import { Box, Flex, VStack, HStack, styled } from "#style/jsx";
import { css } from "#style/css";

import { SEO } from "#root/components/SEO";
import { NavBar } from "#root/components/NavBar";
import { Button } from "#root/components/Button";
import { Typography } from "#root/components/Typography";
import { SectionContainer } from "#root/components/SectionContainer";
import { Footer } from "#root/components/Footer";
import { useTranslation } from "#root/domain/i18n";

export default function Page() {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t("home.title")} />
      <styled.main w="full" backgroundColor="linen" minH="full">
        <Box
          w="full"
          background="conic-gradient(from 28deg at 50% 50%, #FFCE21 0deg, rgba(255, 206, 33, 0.20) 360deg);"
          px="8"
        >
          <NavBar />

          <SectionContainer
            as="header"
            rootStyle={css({ px: "0" })}
            innerStyle={css({
              justifyContent: "center",
              alignItems: "center",
              gap: "20",
              textAlign: "center",
            })}
          >
            <Typography textStyle="titleDisplay" tag="h1">
              {t("home.tagLine")[0]}
              <br />
              {t("home.tagLine")[1]}
            </Typography>
            <Typography
              textStyle="body"
              class={css({ textWrap: "balance", maxW: "40ch", w: "full" })}
            >
              {t("home.tagLineExplanation")}
            </Typography>
            <Button
              tag="a"
              href="/login"
              status="primary"
              color="darkGray"
              ctx="dark"
              size="lg"
            >
              {t("home.getStarted")}
            </Button>
          </SectionContainer>
        </Box>

        <SectionContainer
          innerStyle={css({
            gap: "24",
            md: { gap: "48" },
          })}
        >
          <Typography
            textStyle="titleDisplay"
            class={css({ textAlign: "center", w: "full" })}
            tag="h2"
          >
            {t("home.howDoesItWorkTitle")}
          </Typography>

          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            w="full"
            gap="10"
            lg={{
              gap: "28",
              flexDirection: "row",
            }}
          >
            <AnimatedMessages />
            <VStack justifyContent="flex-start" alignItems="flex-start" gap="6">
              <Typography textStyle="heading4" tag="h3">
                {t("home.audienceQuestionsTitle")}
              </Typography>
              <Typography textStyle="small">
                {t("home.audienceQuestionsText")}
              </Typography>
            </VStack>
          </Flex>

          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            w="full"
            gap="10"
            lg={{
              flexDirection: "row-reverse",
              gap: "28",
            }}
          >
            <QuestionDisplayed />
            <VStack justifyContent="flex-start" alignItems="flex-start" gap="6">
              <Typography textStyle="heading4" tag="h3">
                {t("home.overlayTitle")}
              </Typography>
              <Typography textStyle="small">{t("home.overlayText")}</Typography>
            </VStack>
          </Flex>

          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            w="full"
            gap="10"
            lg={{
              flexDirection: "row",
              gap: "28",
            }}
          >
            <Box maxW="500px" w="full" flexShrink="1">
              <styled.video autoplay loop playsinline muted borderRadius="lg">
                <source src="/videos/backlog.webm" type="video/webm" />
                <source src="/videos/backlog.mp4" type="video/mp4" />
              </styled.video>
            </Box>

            <VStack justifyContent="flex-start" alignItems="flex-start" gap="6">
              <Typography textStyle="heading4" tag="h3">
                {t("home.controlTitle")}
              </Typography>
              <Typography textStyle="small">{t("home.controlText")}</Typography>
            </VStack>
          </Flex>
        </SectionContainer>

        <Box w="full" py="10" aria-hidden="true" overflowX="hidden">
          <HStack w="full" gap="10" transform="translateX(-33%)">
            <For each={new Array(100).fill(undefined)}>
              {() => (
                <Typography
                  textStyle="titleDisplay"
                  class={css({
                    display: "block",
                    whiteSpace: "nowrap",
                    textWrap: "nowrap",
                    fontFamily: "heading",
                    fontSize: "3rem",
                    color: "lightGray",
                  })}
                >
                  {t("home.bannerQuestion")}
                </Typography>
              )}
            </For>
          </HStack>
        </Box>

        <SectionContainer
          rootStyle={css({ backgroundColor: "white" })}
          innerStyle={css({
            justifyContent: "center",
            alignItems: "center",
            gap: "10",
          })}
        >
          <Typography
            textStyle="titleDisplay"
            class={css({ textAlign: "center", maxWidth: "17ch" })}
            tag="h2"
          >
            {t("home.pricingTitle")}
          </Typography>

          <Typography class={css({ textAlign: "center" })}>
            {t("home.pricingText")[0]}
            <br />
            {t("home.pricingText")[1]}
          </Typography>

          <Button tag="a" href="/login" color="darkGray" size="lg">
            {t("home.pricingCta")}
          </Button>
        </SectionContainer>

        <SectionContainer innerStyle={css({ gap: "20" })}>
          <VStack w="full" justifyContent="center" alignItems="center" gap="10">
            <Typography
              textStyle="heading4"
              class={css({ alignSelf: "center" })}
              tag="h2"
            >
              {t("home.whenToUseTitle")}
            </Typography>

            <Typography
              textStyle="small"
              class={css({
                alignSelf: "center",
                maxW: "500px",
                textAlign: "center",
              })}
            >
              {t("home.whenToUseText")}
            </Typography>
          </VStack>

          <styled.ul
            w="full"
            display="grid"
            gridTemplateColumns="1fr"
            gap="4"
            sm={{
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
            lg={{
              gridTemplateColumns: "repeat(4, 1fr)",
            }}
          >
            <Chip
              icon={<Antenna size={32} />}
              content={t("home.whenToUseItems.liveEvent")}
            />
            <Chip
              icon={<Mic size={32} />}
              content={t("home.whenToUseItems.podcasts")}
            />
            <Chip
              icon={<Gamepad2 size={32} />}
              content={t("home.whenToUseItems.gaming")}
            />
            <Chip
              icon={<MessageCircleMore size={32} />}
              content={t("home.whenToUseItems.talkShows")}
            />
            <Chip
              icon={<GraduationCap size={32} />}
              content={t("home.whenToUseItems.education")}
            />
            <Chip
              icon={<Megaphone size={32} />}
              content={t("home.whenToUseItems.marketing")}
            />
            <Chip
              icon={<Globe size={32} />}
              content={t("home.whenToUseItems.politics")}
            />
            <Chip
              icon={<Cpu size={32} />}
              content={t("home.whenToUseItems.marketing")}
            />
          </styled.ul>
        </SectionContainer>

        <Footer />
      </styled.main>
    </>
  );
}

const randomQuestions = [
  "Why start streaming?",
  "How pick your streamer name?",
  "Favorite game to stream?",
  "What inspires you daily?",
  "Education or self-learning preferences?",
  "Best vacation you ever had?",
  "Most challenging situation faced recently?",
  "What's your dream gaming setup?",
  "Follow any fitness routine daily?",
  "How often connect with family?",
  "Favorite subject in school?",
  "Your favorite offline hobby?",
  "Which platforms for gaming?",
  "Collaborate while gaming or solo?",
  "Impact of gaming on life?",
  "Are you a foodie?",
  "What's your stress buster?",
  "Gaming industry's growth perspective?",
  "How balance gaming, life, school?",
  "Next big plan in life?",
];

function AnimatedQuestion({ children }: ParentProps) {
  return (
    <Box py="5" maxW="full" width="fit-content">
      <Box
        backgroundColor="darkGray"
        borderRadius="md"
        px="4"
        py="4"
        whiteSpace="nowrap"
        md={{
          px: "10",
        }}
      >
        <Typography
          textStyle="xxsmallImportant"
          class={css({
            color: "white",
            textOverflow: "ellipsis",
            overflowX: "hidden",
          })}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
}

function AnimatedMessages() {
  const questionsInBuffer = 3;
  const [startIndex, setStartIndex] = createSignal(0);

  // Infinite array
  const questions = () => {
    const initialSet = randomQuestions.slice(
      startIndex(),
      startIndex() + questionsInBuffer,
    );

    const complemetarySet = randomQuestions.slice(
      0,
      questionsInBuffer - initialSet.length,
    );
    return initialSet.concat(complemetarySet);
  };

  setInterval(() => {
    setStartIndex((index) => {
      if (index + 1 === randomQuestions.length) {
        return 0;
      }
      return index + 1;
    });
  }, 3000);

  return (
    <VStack
      gap="0"
      px="10"
      py="8"
      w="full"
      maxW="500px"
      borderRadius="lg"
      backgroundColor="black"
      justifyContent="flex-start"
      alignItems="flex-end"
      flexShrink="0"
      aria-hidden="true"
    >
      <For each={questions()}>
        {(question) => <AnimatedQuestion>{question}</AnimatedQuestion>}
      </For>
    </VStack>
  );
}

function QuestionDisplayed() {
  return (
    <VStack
      w="full"
      maxW="500px"
      flexShrink="0"
      backgroundColor="yellow"
      p="5"
      gap="10"
      borderRadius="lg"
      sm={{
        p: "10",
      }}
      aria-hidden="true"
    >
      <Flex
        w="full"
        flexDirection="column"
        gap="2"
        sm={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography textStyle="xsmallImportant">from superuser1234</Typography>
        <Typography textStyle="xsmallImportant">1 min. ago</Typography>
      </Flex>
      <Typography textStyle="heading3">
        Any tips for getting better at Overwatch 2?
      </Typography>

      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="full"
        gap="5"
        sm={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Button
          tag="span"
          color="darkGray"
          class={css({ pointerEvents: "none" })}
        >
          Next
        </Button>

        <Button
          tag="span"
          status="secondary"
          color="darkGray"
          class={css({ pointerEvents: "none" })}
        >
          Skip
        </Button>
      </Flex>
    </VStack>
  );
}

type ChipProps = {
  icon: JSX.Element;
  content: JSX.Element;
};

function Chip(props: ChipProps) {
  return (
    <styled.li
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      background="white"
      borderRadius="lg"
      border="1px solid rgba(255, 255, 255, 0.1)"
      p="6"
      gap="4"
      w="full"
    >
      <Box>{props.icon}</Box>
      <Typography
        textStyle="xsmallImportant"
        class={css({ color: "darkGray" })}
      >
        {props.content}
      </Typography>
    </styled.li>
  );
}
